

















































































































































































































































.wallet-history {
  .box-member {
    margin-bottom: 20px;
    border: none;
    -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    border-radius: 3px;
    background-color: #fff;
    min-height: 200px;
    width: 100%;
    .title {
      font-weight: 600;
      font-size: 20px;
      color: #2f394e;
      text-align: left;
      padding: 0 0 5px;
      text-transform: uppercase;
      width: 100%;
      padding: 5px 1.25rem;
    }
    .table-search {
      font-weight: 600;
      font-size: 20px;
      color: #2f394e;
      text-align: left;
      padding: 0 0 5px;
      border-top: 3px solid rgba($color: #000000, $alpha: 0.08);
      border-bottom: 3px solid rgba($color: #000000, $alpha: 0.08);
      text-transform: uppercase;
      width: 100%;
      min-height: 50px;
      padding: 5px 1.25rem;
      display: flex;
      flex-wrap: wrap;
      input {
        max-width: 225px;
      }
      select,
      input {
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid #6c757d;
        height: 40px;
        font-size: clamp(15px, 1vw, 16.2px);
        font-weight: 600;
        position: relative;
        &:focus {
          box-shadow: none;
          border-bottom: 2px solid #097501;
        }
        &::placeholder {
          font-weight: 500;
          font-size: clamp(13px, 1vw, 15px);
        }
      }
      button {
        &.btn-egg {
          min-width: 105px;
          height: 40px;
        }
        width: auto;
        min-width: unset;
        font-weight: 600;
      }
    }
    .table-reponsive {
      width: calc(100% - 10px);
      overflow-x: auto;
      overflow-y: hidden;
      padding: 10px 5px;
      position: relative;
      box-sizing: content-box;
      table {
        width: 100%;
        border-radius: 10px;
        th,
        td {
          min-width: 100px;
          white-space: pre;
          padding: 5px 0.75rem;
        }
        thead {
          background: #009750;
          border-radius: 10px;
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(0, 0, 0, 0.1);
          border: 0;
          color: #fff;
          font-weight: 600;
          tr {
            border-radius: 10px;
            th {
              border: 0;
              &:last-child {
                border-radius: 0px 10px 10px 0px;
              }
              &:first-child {
                border-radius: 10px 0 0 10px;
              }
            }
          }
        }
        tbody:before {
          content: '@';
          display: block;
          line-height: 10px;
          text-indent: -99999px;
        }
      }
    }
    .table-paginate {
      border-top: 3px solid rgba($color: #000000, $alpha: 0.08);
    }
  }
}
